import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const FoundationPage: React.FC = () => {
  const { t } = useTranslation()

  const content = {
    pretitle: t(
      'our-platform.foundation_super',
      'fill copy key: our-platform.foundation_super',
    ),
    title: t(
      'our-platform.foundation_title',
      'fill copy key: our-platform.foundation_title',
    ),
    text: t(
      'our-platform.foundation_body',
      'fill copy key: our-platform.foundation_body',
    ),

    links: [
      {
        link: t(
          'our-platform.foundation_cta',
          'fill copy key: our-platform.foundation_cta',
        ),
        url: '/our-platform/modules',
        variant: 'all',
      },
    ],
  }

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box col={0.6} />
                <Box col={0.4}>
                  <Text {...content} />
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default FoundationPage
